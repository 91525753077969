export const TEETH = [
    11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33, 34, 35, 36, 37, 38,
    41, 42, 43, 44, 45, 46, 47, 48,
].map((toothNumber) => {
    return {
        id: toothNumber,
        icon: import(`../../assets/data/teeth/tooth_${toothNumber}.svg`).then((module) => {
            return module.default;
        }),
    };
});
